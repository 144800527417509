<template>
    <div class="row g-5 g-xl-8 mb-5 mb-xl-0">
        <div class="col-xl-6" v-if="statistic.general">
            <div class="card-xl-stretch mb-xl-8 bg-primary card">
                <div class="card-body">
                    <span class="svg-icon svg-icon-white svg-icon-3x">
                        <inline-svg src="/media/icons/duotune/graphs/gra001.svg"/>
                    </span>
                    <div class="text-inverse-danger fw-bold fs-2 mb-2 mt-5">{{ $t('pages.statistic.general.general.title') }}</div>
                    <div class="row fs-6">
                        <div class="col-sm-2 mb-1 mb-sm-0">
                            <div class="text-inverse-danger fw-bold me-2">{{ $t('pages.statistic.general.general.cols.product') }}</div>
                            <div class="text-inverse-danger">{{ statistic.general.product }}</div>
                        </div>
                        <div class="col-sm-2 mb-1 mb-sm-0 text-start text-sm-center">
                            <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.general.general.cols.admin') }}</div>
                            <div class="text-inverse-danger">{{ statistic.general.admin }}</div>
                        </div>
                        <div class="col-sm-2 mb-1 mb-sm-0 text-start text-sm-center">
                            <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.general.general.cols.user') }}</div>
                            <div class="text-inverse-danger">{{ statistic.general.user }}</div>
                        </div>
                        <div class="col-sm-2 mb-1 mb-sm-0 text-start text-sm-center">
                            <el-tooltip :content="$t('pages.statistic.general.general.tooltip.purchaserUser')" effect="light">
                                <div>
                                    <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.general.general.cols.purchaserUser') }}</div>
                                    <div class="text-inverse-danger">{{ statistic.general.purchaser_user }}</div>
                                </div>
                            </el-tooltip>
                        </div>
                        <div class="col-sm-2 mb-1 mb-sm-0 text-start text-sm-center">
                            <el-tooltip :content="$t('pages.statistic.general.general.tooltip.recordStreamCount')" effect="light">
                                <div>
                                    <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.general.general.cols.recordStreamCount') }}</div>
                                    <div class="text-inverse-danger">{{ statistic.general.record_stream_count }}</div>
                                </div>
                            </el-tooltip>
                        </div>
                        <div class="col-sm-2 text-start text-sm-end">
                            <el-tooltip :content="$t('pages.statistic.general.general.tooltip.recordStreamWatchingHour')" effect="light">
                                <div>
                                    <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.general.general.cols.recordStreamWatchingHour') }}</div>
                                    <div class="text-inverse-danger">{{ statistic.general.record_stream_watching_hour }}</div>
                                </div>
                            </el-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-6" v-if="statistic.exam_common">
            <div class="card-xl-stretch mb-xl-8 bg-info card">
                <div class="card-body">
                    <span class="svg-icon svg-icon-white svg-icon-3x">
                        <inline-svg src="/media/icons/duotune/graphs/gra001.svg"/>
                    </span>
                    <div class="text-inverse-danger fw-bold fs-2 mb-2 mt-5">{{ $t('pages.statistic.general.exam.common.title') }}</div>
                    <div class="row fs-6">
                        <div class="col-sm-6 mb-1 mb-sm-0">
                            <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.general.exam.common.cols.total') }}</div>
                            <div class="text-inverse-danger">{{ statistic.exam_common.total }}</div>
                        </div>
                        <div class="col-sm-6 text-start text-sm-end">
                            <div class="text-inverse-danger fw-bold">{{ $t('pages.statistic.general.exam.common.cols.answered') }}</div>
                            <div class="text-inverse-danger">{{ statistic.exam_common.answered }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row g-5 g-xl-8 mb-5 mb-7">
        <div class="col-lg-6">
            <div class="card">
                <div class="card-header border-0 pt-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label fw-bolder fs-3 mb-1">{{ $t('pages.statistic.general.login.title')}}</span>
                    </h3>

                    <div class="card-toolbar" data-kt-buttons="true">
                        <a :class="login.tab == 'login' && 'active'" @click="changeLoginStatisticTab('login')" class="btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1">{{ $t('pages.statistic.general.login.tab.general')}}</a>
                        <a :class="login.tab == 'last_login' && 'active'" @click="changeLoginStatisticTab('last_login')" class="btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1">{{ $t('pages.statistic.general.login.tab.last')}}</a>
                        <a :class="login.tab == 'most_login' && 'active'" @click="changeLoginStatisticTab('most_login')" class="btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1">{{ $t('pages.statistic.general.login.tab.most')}}</a>
                    </div>
                </div>

                <div class="card-body" v-if="login.showChart">
                    <apexchart type="bar" :options="login.options" :series="login.series" :height="215"></apexchart>
                </div>
            </div>
        </div>
        <div class="col-lg-6" v-if="statistic.sale_min_and_max_product">
            <div class="card">
                <div class="card-header border-0 pt-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label fw-bolder fs-3 mb-1">{{ $t('pages.statistic.general.saleMinMaxProduct.title')}}</span>
                    </h3>

                    <div class="card-toolbar" data-kt-buttons="true">
                        <a :class="saleMinMaxProduct.tab == 'min' && 'active'" @click="changeSaleMinMaxProductTab('min')" class="btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1">{{ $t('pages.statistic.general.saleMinMaxProduct.tab.min')}}</a>
                        <a :class="saleMinMaxProduct.tab == 'max' && 'active'" @click="changeSaleMinMaxProductTab('max')" class="btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1">{{ $t('pages.statistic.general.saleMinMaxProduct.tab.max')}}</a>
                    </div>
                </div>

                <div class="card-body" v-if="saleMinMaxProduct.showChart">
                    <apexchart type="bar" :options="saleMinMaxProduct.options" :series="saleMinMaxProduct.series" :height="215"></apexchart>
                </div>
            </div>
        </div>
    </div>
    <div class="row g-5 g-xl-8 mb-5 mb-7">
        <div class="col-lg-6">
            <div class="card">
                <div class="card-header border-0 pt-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label fw-bolder fs-3 mb-1">{{ $t('pages.statistic.general.saleReport.title')}}</span>
                    </h3>
                </div>

                <div class="card-body" v-if="saleReport.showChart">
                    <apexchart type="bar" :options="saleReport.options" :series="saleReport.series" :height="215"></apexchart>
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="card">
                <div class="card-header border-0 pt-5">
                    <h3 class="card-title align-items-start flex-column">
                        <span class="card-label fw-bolder fs-3 mb-1">{{ $t('pages.statistic.general.exam.user.title')}}</span>
                    </h3>

                    <div class="card-toolbar" data-kt-buttons="true">
                        <a :class="examUser.tab == 'exam_general_best_user' && 'active'" @click="changeExamUserTab('exam_general_best_user')" class="btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1">{{ $t('pages.statistic.general.exam.user.tab.generalBest')}}</a>
                        <a :class="examUser.tab == 'exam_most_answered_user' && 'active'" @click="changeExamUserTab('exam_most_answered_user')" class="btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1">{{ $t('pages.statistic.general.exam.user.tab.mostAnswered')}}</a>
                    </div>
                </div>

                <div class="card-body" v-if="examUser.showChart">
                    <apexchart type="bar" :options="examUser.options" :series="examUser.series" :height="215"></apexchart>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getCSSVariableValue } from "@/assets/js/_utils";
export default {
    name: "index",
    data(){
        return {
            login: {
                tab: 'general',
                options: {
                    chart: {
                        fontFamily: "inherit",
                        type: "bar",
                        toolbar: {
                            show: false,
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: ["30%"],
                        },
                    },
                    legend: {
                        show: false,
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ["transparent"],
                    },
                    xaxis: {
                        categories: [],
                        axisBorder: {
                            show: false,
                        },
                        axisTicks: {
                            show: false,
                        },
                        labels: {
                            style: {
                                colors: getCSSVariableValue("--bs-gray-500"),
                                fontSize: "12px",
                            },
                        },
                    },
                    yaxis: {
                        labels: {
                            style: {
                                colors: getCSSVariableValue("--bs-gray-500"),
                                fontSize: "12px",
                            },
                        },
                    },
                    fill: {
                        opacity: 1,
                    },
                    states: {
                        normal: {
                            filter: {
                                type: "none",
                                value: 0,
                            },
                        },
                        hover: {
                            filter: {
                                type: "none",
                                value: 0,
                            },
                        },
                        active: {
                            allowMultipleDataPointsSelection: false,
                            filter: {
                                type: "none",
                                value: 0,
                            },
                        },
                    },
                    tooltip: {
                        shared: true,
                        style: {
                            fontSize: "12px",
                        },
                        y: {
                            formatter:  (val) => {
                                return val;
                            },
                        },
                    },
                    colors: [getCSSVariableValue("--bs-warning"), getCSSVariableValue("--bs-gray-300")],
                    grid: {
                        borderColor: getCSSVariableValue("--bs-gray-200"),
                        strokeDashArray: 4,
                        yaxis: {
                            lines: {
                                show: true,
                            },
                        },
                    },
                },
                series: [{name: this.$t('pages.statistic.general.login.serial.loginCount'), data: []}],
                showChart: false,
            },
            saleMinMaxProduct: {
                tab: 'min',
                options: {
                    chart: {
                        fontFamily: "inherit",
                        type: "bar",
                        toolbar: {
                            show: false,
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: ["30%"],
                        },
                    },
                    legend: {
                        show: false,
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ["transparent"],
                    },
                    xaxis: {
                        categories: [],
                        axisBorder: {
                            show: false,
                        },
                        axisTicks: {
                            show: false,
                        },
                        labels: {
                            style: {
                                colors: getCSSVariableValue("--bs-gray-500"),
                                fontSize: "12px",
                            },
                        },
                    },
                    yaxis: {
                        labels: {
                            style: {
                                colors: getCSSVariableValue("--bs-gray-500"),
                                fontSize: "12px",
                            },
                        },
                    },
                    fill: {
                        opacity: 1,
                    },
                    states: {
                        normal: {
                            filter: {
                                type: "none",
                                value: 0,
                            },
                        },
                        hover: {
                            filter: {
                                type: "none",
                                value: 0,
                            },
                        },
                        active: {
                            allowMultipleDataPointsSelection: false,
                            filter: {
                                type: "none",
                                value: 0,
                            },
                        },
                    },
                    tooltip: {
                        shared: true,
                        style: {
                            fontSize: "12px",
                        },
                        y: {
                            formatter:  (val) => {
                                return val;
                            },
                        },
                    },
                    colors: [getCSSVariableValue("--bs-warning"), getCSSVariableValue("--bs-gray-300")],
                    grid: {
                        borderColor: getCSSVariableValue("--bs-gray-200"),
                        strokeDashArray: 4,
                        yaxis: {
                            lines: {
                                show: true,
                            },
                        },
                    },
                },
                series: [{name: this.$t('pages.statistic.general.saleMinMaxProduct.serial.saleCount'), data: []}],
                showChart: false,
            },
            examUser: {
                tab: 'exam_general_best_user',
                options: {
                    chart: {
                        fontFamily: "inherit",
                        type: "bar",
                        toolbar: {
                            show: false,
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: ["30%"],
                        },
                    },
                    legend: {
                        show: false,
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ["transparent"],
                    },
                    xaxis: {
                        categories: [],
                        axisBorder: {
                            show: false,
                        },
                        axisTicks: {
                            show: false,
                        },
                        labels: {
                            style: {
                                colors: getCSSVariableValue("--bs-gray-500"),
                                fontSize: "12px",
                            },
                        },
                    },
                    yaxis: {
                        labels: {
                            style: {
                                colors: getCSSVariableValue("--bs-gray-500"),
                                fontSize: "12px",
                            },
                        },
                    },
                    fill: {
                        opacity: 1,
                    },
                    states: {
                        normal: {
                            filter: {
                                type: "none",
                                value: 0,
                            },
                        },
                        hover: {
                            filter: {
                                type: "none",
                                value: 0,
                            },
                        },
                        active: {
                            allowMultipleDataPointsSelection: false,
                            filter: {
                                type: "none",
                                value: 0,
                            },
                        },
                    },
                    tooltip: {
                        style: {
                            fontSize: "12px",
                        },
                        y: {
                            formatter:  (val, {seriesIndex, dataPointIndex}) => {
                                let data = this.examUser.series[seriesIndex].data[dataPointIndex];
                                if(data.average_grade) {
                                    val += this.sprintf(' (%s: %%s)', [this.$t('pages.statistic.general.exam.user.averageGrade'), data.average_grade])
                                }

                                return val;
                            },
                        },
                    },
                    colors: [getCSSVariableValue("--bs-warning"), getCSSVariableValue("--bs-gray-300")],
                    grid: {
                        borderColor: getCSSVariableValue("--bs-gray-200"),
                        strokeDashArray: 4,
                        yaxis: {
                            lines: {
                                show: true,
                            },
                        },
                    },
                },
                series: [{name: this.$t('pages.statistic.general.exam.user.serial.answeredCount'), data: []}],
                showChart: false,
            },
            saleReport: {
                options: {
                    chart: {
                        fontFamily: "inherit",
                        type: "bar",
                        toolbar: {
                            show: false,
                        },
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: ["30%"],
                        },
                    },
                    legend: {
                        show: false,
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        show: true,
                        width: 2,
                        colors: ["transparent"],
                    },
                    xaxis: {
                        categories: [],
                        axisBorder: {
                            show: false,
                        },
                        axisTicks: {
                            show: false,
                        },
                        labels: {
                            style: {
                                colors: getCSSVariableValue("--bs-gray-500"),
                                fontSize: "12px",
                            },
                        },
                    },
                    yaxis: {
                        labels: {
                            style: {
                                colors: getCSSVariableValue("--bs-gray-500"),
                                fontSize: "12px",
                            },
                        },
                    },
                    fill: {
                        opacity: 1,
                    },
                    states: {
                        normal: {
                            filter: {
                                type: "none",
                                value: 0,
                            },
                        },
                        hover: {
                            filter: {
                                type: "none",
                                value: 0,
                            },
                        },
                        active: {
                            allowMultipleDataPointsSelection: false,
                            filter: {
                                type: "none",
                                value: 0,
                            },
                        },
                    },
                    tooltip: {
                        shared: true,
                        style: {
                            fontSize: "12px",
                        },
                        y: {
                            formatter:  (val, {seriesIndex, dataPointIndex}) => {
                                let data = this.saleReport.series[seriesIndex].data[dataPointIndex];
                                if(data.price) {
                                    val += this.sprintf(' (%s: %d %s)', [this.$t('pages.statistic.general.saleReport.price'), data.price, this.$root.defaultCurrency.suffix])
                                }

                                return val;
                            },
                        },
                    },
                    colors: [getCSSVariableValue("--bs-warning"), getCSSVariableValue("--bs-gray-300")],
                    grid: {
                        borderColor: getCSSVariableValue("--bs-gray-200"),
                        strokeDashArray: 4,
                        yaxis: {
                            lines: {
                                show: true,
                            },
                        },
                    },
                },
                series: [{name: this.$t('pages.statistic.general.saleReport.serial.count'), data: []}],
                showChart: false,
            }
        }
    },
    computed:{
        statistic(){
            return this.$store.state.statistic.general.table.data;
        }
    },
    mounted(){
        this.loadStatistics();
    },
    methods: {
        loadStatistics(){
            this.$store.dispatch('statistic/general/get', {}).then(() => {
                this.changeLoginStatisticTab('login');
                this.changeSaleMinMaxProductTab('min');
                this.changeExamUserTab('exam_general_best_user');
                this.loadSaleReportStatistic();
            });
        },
        changeLoginStatisticTab(tab){
            this.login.showChart = false;
            this.login.tab = tab;
            let statistic = this.statistic[tab];
            let methodKey = this.toCamelCase(tab + '_statistic');
            this.login.options.yaxis.labels.show = true;

            let [seriesData, categories] = this[methodKey] ? this[methodKey](statistic) : [[], []];

            this.login.options.xaxis.categories = categories;
            this.login.series[0].data = seriesData;

            this.$nextTick(() => {
                this.login.showChart = true;
            })
        },
        loginStatistic(statistic){
            let seriesData = [];
            let categories = [];

            for(let key in statistic){
                let data = statistic[key];
                seriesData.push(data);
                categories.push((this.$t('common.' + key)));
            }

            return [seriesData, categories];
        },
        lastLoginStatistic(statistic){
            let seriesData = [];
            let categories = [];

            this.login.options.yaxis.labels.show = false;

            statistic.forEach((data) => {
                seriesData.push(1);
                categories.push(this.sprintf('%s#%d', [data.full_name, data.user_id]));
            })

            return [seriesData, categories];
        },
        mostLoginStatistic(statistic){
            let seriesData = [];
            let categories = [];

            statistic.forEach((data) => {
                seriesData.push(data.login_count);
                categories.push(this.sprintf('%s#%d', [data.full_name, data.user_id]));
            })

            return [seriesData, categories];
        },
        changeSaleMinMaxProductTab(tab){
            this.saleMinMaxProduct.showChart = false;
            this.saleMinMaxProduct.tab = tab;
            let statistic = this.statistic.sale_min_and_max_product[tab];
            let seriesData = [];
            let categories = [];

            statistic.forEach((data) => {
                seriesData.push(data.sale_count);
                categories.push(this.sprintf('%s#%d', [data.title, data.product_id]));
            })

            this.saleMinMaxProduct.options.xaxis.categories = categories;
            this.saleMinMaxProduct.series[0].data = seriesData;

            this.$nextTick(() => {
                this.saleMinMaxProduct.showChart = true;
            })
        },
        changeExamUserTab(tab){
            this.examUser.showChart = false;
            this.examUser.tab = tab;
            let statistic = this.statistic[tab];
            let seriesData = [];

            statistic.forEach((data) => {
                seriesData.push({
                    x: this.sprintf('%s#%d', [data.full_name, data.user_id]),
                    y: data.answered_count,
                    average_grade: data.average_grade
                });
            })

            this.examUser.series[0].data = seriesData;

            this.$nextTick(() => {
                this.examUser.showChart = true;
            })
        },
        loadSaleReportStatistic(){
            this.saleReport.showChart = false;
            let statistic = this.statistic.sale_report;

            let seriesData = [{
                x: this.$t('pages.statistic.general.saleReport.total'),
                y: statistic.total_count,
                price: statistic.total
            }];

            ['daily', 'weekly', 'monthly'].forEach((key) => {
                let count = statistic[key + '_count'];
                let price = statistic[key + '_price'];

                seriesData.push({
                    x: this.$t('common.' + key),
                    y: count,
                    price: price,
                });
            })

            this.saleReport.series[0].data = seriesData;

            this.$nextTick(() => {
                this.saleReport.showChart = true;
            })
        }
    }
}
</script>

<style scoped>

</style>